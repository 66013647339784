import { faUpload, faHistory, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { HISTORY_ROUTE } from "templates/history/history.routes";
import { useAuthContext } from "logic/auth-context";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { useSidebarMenuContext } from "../context";
import { SURVEYS_ROUTE } from "templates/surveys/survey.routes";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { RAG_ROUTE } from "templates/rag/rag.routes";
import { handleNewStudy } from "@/store/modules/study/utils/hanlde-new-study";

export enum TopMenuKeys {
  HOME = "home",
  SURVEYS = "surveys",
  SEE_ALL_HISTORY = "see-all-history",
  RECENT_STUDIES = "recent-studies",
  RAG = "rag",
}

type TopMenuProps = {
  collapsed?: boolean;
};

export const TopMenu: React.FC<TopMenuProps> = ({ collapsed }) => {
  const auth = useAuthContext();
  // Router
  const router = useRouter();

  const workspaces = useAppSelector((state) => state.workspaces.data);

  const history = useAppSelector((state) => state.history);

  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );

  const isSignedIn = useMemo(() => auth.session.user, [auth.session.user]);

  const { ragEnabled, surveysEnabled } = useSidebarMenuContext();

  // Top Menu Items
  const topMenuItems: Required<MenuProps>["items"] = useMemo(() => {
    // Home menu item
    const newStudy: ItemType = {
      label: "Home",
      key: TopMenuKeys.HOME,
      icon: <FontAwesomeIcon icon={faHome} />,
    };

    const surveys: ItemType = {
      label: "Surveys (Beta)",
      key: TopMenuKeys.SURVEYS,
      icon: <FontAwesomeIcon icon={faWpforms} />,
    };

    // Recent Studies (Collapsible menu item)
    const recent: ItemType = {
      label: "Recent studies",
      key: TopMenuKeys.RECENT_STUDIES,
      icon: <FontAwesomeIcon icon={faHistory} />,
      children: history.data
        ?.slice(0, 3)
        .map((item) => ({
          label: item.description, // replace 'title' with the actual property name
          key: `${TopMenuKeys.RECENT_STUDIES}_${item.id}`,
        }))
        .concat({
          label: "See all history",
          key: TopMenuKeys.SEE_ALL_HISTORY,
        }),
    };

    // Rag menu item
    const rag: ItemType = {
      label: "Import Your Data",
      key: TopMenuKeys.RAG,
      icon: <FontAwesomeIcon icon={faUpload} />,
    };

    const items: ItemType[] = [newStudy];

    if (isSignedIn) {
      items.push(recent);

      if (activeSubscription) {
        if (surveysEnabled) {
          items.push(surveys);
        }

        if (ragEnabled) {
          items.push(rag);
        }
      }
    }

    return items;
  }, [isSignedIn, history, ragEnabled, surveysEnabled, activeSubscription]);

  // Top Menu Items Selected Keys
  const topMenuItemsSelectedKeys = useMemo(() => {
    const isHistory = router.asPath.startsWith("/history");
    const isRag = router.asPath.startsWith("/rag");
    const isSurveys = router.asPath.startsWith("/surveys");
    const workspaceKey = workspaces.workspace && `workspace-${workspaces.workspace?.id}`;
    const studyKey = router.query.studyId
      ? `${TopMenuKeys.RECENT_STUDIES}_${router.query.studyId as string}`
      : "";

    const selectedKeys = [];

    if (workspaceKey) selectedKeys.push(workspaceKey);
    if (studyKey) selectedKeys.push(studyKey);
    if (isSurveys) selectedKeys.push(TopMenuKeys.SURVEYS);
    if (isHistory) selectedKeys.push(TopMenuKeys.SEE_ALL_HISTORY);
    if (isRag) selectedKeys.push(TopMenuKeys.RAG);

    return selectedKeys;
  }, [router.asPath, workspaces.workspace, router.query.studyId]);

  // Menu Select Handler
  const onMenuSelect = useCallback(
    async (key: string) => {
      if (key.startsWith(TopMenuKeys.RECENT_STUDIES)) {
        const id = key.split("_")[1];
        if (!id) return;
        router.push(STUDY_ROUTE.getHref(id));
        return;
      }

      switch (key) {
        case TopMenuKeys.HOME:
          analytics.track("workspace:sidebar:home");
          await router.push(INDEX_ROUTE.getHref());

          handleNewStudy();

          break;
        case TopMenuKeys.SURVEYS:
          analytics.track("workspace:sidebar:surveys");
          router.push(SURVEYS_ROUTE.getHref());
          break;
        case TopMenuKeys.RAG:
          analytics.track("workspace:sidebar:rag");
          router.push(RAG_ROUTE.getHref());
          break;
        case TopMenuKeys.SEE_ALL_HISTORY:
          analytics.track("workspace:sidebar:all-history");
          router.push(HISTORY_ROUTE.getHref());
          break;
      }
    },
    [router]
  );

  return (
    <>
      {/* Top Menu */}
      <Menu
        selectedKeys={topMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        defaultOpenKeys={collapsed ? [] : [TopMenuKeys.RECENT_STUDIES]}
        style={{ backgroundColor: "transparent" }}
        mode="inline"
        items={topMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
    </>
  );
};
