import { ReactNode, useCallback, useState } from "react";
import { SidebarMenuContext } from ".";
import { useSidebarMenuCollapsed } from "logic/hooks/sidebar-menu-collapsed";

type WorkspacePageContextProviderProps = {
  children: ReactNode;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  isImpersonating?: boolean;
};

export const SidebarMenuContextProvider: React.FC<WorkspacePageContextProviderProps> = ({
  children,
  ragEnabled,
  surveysEnabled,
  isImpersonating,
}) => {
  const { getSidebarMenuCollapsed, toggleSidebarMenuCollapsed } = useSidebarMenuCollapsed();

  const [sidebarMenuCollapsed, _setSidebarMenuCollapsed] =
    useState<boolean>(getSidebarMenuCollapsed());

  const setSidebarMenuCollapsed = useCallback(() => {
    _setSidebarMenuCollapsed((prev) => {
      toggleSidebarMenuCollapsed(!prev);
      return !prev;
    });
  }, [toggleSidebarMenuCollapsed]);

  return (
    <SidebarMenuContext.Provider
      value={{
        ragEnabled,
        surveysEnabled,
        isImpersonating,
        sidebarMenuCollapsed,
        setSidebarMenuCollapsed,
      }}
    >
      {children}
    </SidebarMenuContext.Provider>
  );
};
