import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { HISTORY_ROUTE } from "templates/history/history.routes";
import { PROJECT_SETTINGS_ROUTE } from "templates/project-settings/project-settings.routes";
import { PROJECTS_ROUTE } from "templates/projects/projects.routes";
import { RAG_ROUTE } from "templates/rag/rag.routes";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { SURVEYS_ROUTE } from "templates/surveys/survey.routes";
import { USER_ROUTE } from "templates/user/user-routes";
import { WORKSPACE_PLANS_ROUTE } from "templates/workspace-plans/workspace-plans-routes";
import {
  WORKSPACE_SETTINGS_ROUTE,
  WORKSPACE_SIGNUP_ROUTE,
} from "templates/workspace-settings/workspace-settings.routes";
import { WORKSPACES_ROUTE } from "templates/workspaces/workspaces.routes";

export function useGetCurrentPathBreadcrumb() {
  const router = useRouter();
  const pathname = usePathname();
  const { studyId, surveyId, workspaceId, projectId } = router.query;
  const currentStudy = useAppSelector((state) => state.study.data);
  const surveys = useAppSelector((state) => state.surveys.data);

  const currentPathBreadcrumb: string | undefined = useMemo(() => {
    if (pathname.startsWith(HISTORY_ROUTE.path)) {
      return "All Studies";
    }

    if (pathname.startsWith(PROJECT_SETTINGS_ROUTE.path)) {
      return projectId ? "Project Settings" : "New Project";
    }

    if (pathname.startsWith(PROJECTS_ROUTE.path)) {
      return "All Projects";
    }

    if (pathname.startsWith(RAG_ROUTE.path)) {
      return "RAG";
    }

    if (pathname.startsWith(STUDY_ROUTE.path)) {
      const study = currentStudy?.id === studyId ? currentStudy : undefined;
      return study?.description ?? "New Study";
    }

    if (pathname.startsWith(SURVEYS_ROUTE.path)) {
      const survey = surveys?.projectSurveys?.find((survey) => survey.id === surveyId);
      return survey?.name ?? "All Surveys";
    }

    if (pathname.startsWith(USER_ROUTE.path)) {
      return "Account Settings";
    }

    if (pathname.startsWith(WORKSPACE_PLANS_ROUTE.path)) {
      return "Subscription Plans";
    }

    if (pathname.startsWith(WORKSPACE_SETTINGS_ROUTE.path)) {
      const isWorkspaceSignUp = pathname.endsWith(WORKSPACE_SIGNUP_ROUTE.path);
      return workspaceId
        ? "Workspace Settings"
        : isWorkspaceSignUp
          ? "Your Workspaces"
          : "New Workspace";
    }

    if (pathname.startsWith(WORKSPACES_ROUTE.path)) {
      return "All Workspaces";
    }
  }, [pathname, currentStudy, studyId, surveys, surveyId, workspaceId, projectId]);

  const showProjectBreadcrumb = useMemo(() => {
    return !(
      pathname.startsWith(WORKSPACES_ROUTE.path) ||
      pathname.startsWith(WORKSPACE_SETTINGS_ROUTE.path) ||
      pathname.startsWith(WORKSPACE_SIGNUP_ROUTE.path) ||
      pathname.startsWith(PROJECTS_ROUTE.path) ||
      pathname.startsWith(WORKSPACE_PLANS_ROUTE.path)
    );
  }, [pathname]);

  return { currentPathBreadcrumb, showProjectBreadcrumb };
}
