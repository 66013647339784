import { resetSelectedAudiences } from "../../audiences/slice";
import { resetSelectedProblems } from "../../problems/slice";

import { resetResearchAssistant } from "../../research-assistant/slice";

import { store } from "store";

import { resetSelectedSolution } from "../../solutions/slice";
import { resetResearchGoal } from "../../research-goal/slice";
import { resetCustomScript } from "../../custom-script/slice";
import { resetStudy } from "../slice";
import { knowledgeGraphApi } from "../../knowledge-graph/slice";
import { resetSelectedRagFiles } from "../../rag/slice";
import { resetSummaryConversations } from "../../summaries-conversations/slice";
import { resetUserInterviewConversations } from "../../user-interview-conversations/slice";
import { resetSyntheticUsers } from "../../synthetic-users/slice";
import { resetUserInterviews } from "../../user-interviews/slice";
import { resetSummary } from "../../summaries/slice";

export const handleNewStudy = () => {
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetSelectedAudiences());
  store.dispatch(resetSelectedProblems());
  store.dispatch(resetSelectedSolution());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetCustomScript());
  store.dispatch(resetStudy());
  store.dispatch(knowledgeGraphApi.util.invalidateTags(["KnowledgeGraph"]));
  store.dispatch(resetSelectedRagFiles());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(resetSummary());
  store.dispatch(resetSummaryConversations());
};
